import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './authentication';
import outageReducer from './outage';
import staffReducer from './staff';
import currentRosterReducer from './currenRoster';
import candidatesReducer from './candidates';
import pipelineReducer from './pipeline';
import alumniReducer from './alumni';
import staffingReducer from './staffing';
import experienceReducer from './experience';
import pastCaseReducer from './pastCase';
import staffingCapacityReducer from './staffingCapacity';
import staffingRosterMemberCandidatesReducer from './staffingRosterMemberCandidates';

const store = makeStore();

export default store;
export function makeStore(initialState) {
  return configureStore({
    reducer: {
      auth: authenticationReducer,
      outage: outageReducer,
      staff: staffReducer,
      currentRoster: currentRosterReducer,
      pipeline: pipelineReducer,
      candidates: candidatesReducer,
      alumni: alumniReducer,
      experience: experienceReducer,
      staffing: staffingReducer,
      pastCases: pastCaseReducer,
      staffingCapacity: staffingCapacityReducer,
      staffingRosterMemberCandidates: staffingRosterMemberCandidatesReducer,
    },
    ...(initialState ? { preloadedState: initialState } : {}),
  });
}
